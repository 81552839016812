<template>
  <div class="users-trash fixed-draggable-dynamic-table-wrapper-height">
    <draggable-dynamic-table ref="customersTrashList"
                             :options="options"
                             :columns="columnsLabel"
                             :data="data"
                             @user:restore="restoreUser($event)"
                             @user:deleted="$refs.deleteConfirmation.showDialog(), userDataForDelete = $event"
                             @filter:remove="setFilters($event)"
                             @filter:set="setFilters($event)"
                             @sort:set="setSort($event)"
                             @load:more="getUsersTrash()"/>

    <custom-dialog ref="deleteConfirmation"
                   :title="$t('users.user.confirmations.forceDelete.title')"
                   :body="$t('users.user.confirmations.forceDelete.body', {name: userDataForDelete.name})"
                   @accept="deleteUser(userDataForDelete)"
                   @close=""/>
  </div>
</template>

<script>
import axios from 'axios'
import DraggableDynamicTable from '@/components/draggableDynamicTable/draggableDynamicTable'
import {deleteUser, getUsersTrash, restoreUser} from '@/http/requests/users/users'
import CustomDialog from '@/components/customDialog/customDialog'
import {checkUserPermissions} from '@/assets/js/functions'

export default {
  name: 'usersTrash',
  components: {CustomDialog, DraggableDynamicTable},
  data () {
    return {
      options: {
        id: 'usersTrashList',
        rowKeyField: 'id'
      },
      columnsLabel: [
        {
          field: 'storeAction',
          i18n: 'users.table.header.restore',
          color: 'success',
          width: '70px',
          minWidth: 70,
          locked: true,
          actions: true,
          showAction: 'always',
          event: 'user:restore',
          action: {
            icon: 'icon-refresh-cw',
            iconPack: 'feather',
            color: 'success',
            type: 'button'
          },
          classes: 'mx-auto'
        },
        {
          field: 'deleteAction',
          i18n: 'users.table.header.delete',
          color: 'danger',
          width: '70px',
          minWidth: 70,
          align: 'center',
          locked: true,
          actions: true,
          showAction: 'always',
          event: 'user:deleted',
          action: {
            icon: 'icon-trash',
            iconPack: 'feather',
            color: 'danger',
            type: 'button'
          },
          classes: 'mx-auto'
        },
        {
          field: 'registryDate',
          i18n: 'users.table.header.registryDate',
          width: 'calc(100% / 10)',
          minWidth: 225,
          align: 'center',
          filter: true,
          filterType: 'date',
          sortable: true
          // locked: true
        },
        {
          field: 'phoneNumber',
          i18n: 'users.table.header.phoneNumber',
          width: 'calc((100% / 10))',
          minWidth: 200,
          align: 'center',
          sortable: true,
          // locked: true,
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ]
        },
        {
          field: 'name',
          i18n: 'users.table.header.name',
          width: 'calc((100% / 10) * 2)',
          minWidth: 250,
          sortable: true,
          // locked: true,
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ]
        },
        {
          field: 'gender',
          i18n: 'users.table.header.gender',
          width: 'calc(100% / 10)',
          minWidth: 100,
          align: 'center',
          sortable: true,
          filter: true,
          filterType: 'select',
          filterTypes: [
            {
              label: this.$t('users.genderTypes.all'),
              value: 0
            },
            {
              label: this.$t('users.genderTypes.man'),
              value: 1
            },
            {
              label: this.$t('users.genderTypes.woman'),
              value: 2
            }
          ]
        },
        {
          field: 'row',
          i18n: 'users.table.header.row',
          width: '50px',
          minWidth: 50,
          align: 'center',
          locked: true,
          // sortable: true,
          footer: {}
        }
      ],
      filters: [this.roleId > 0 ? `role=${this.roleId}` : ''],
      sorts: ['order[0]=created_at,desc'],
      data: [],
      page: 1,
      endedList: false,
      actions: {
        leftToolbar: [
          {
            id: 'printTable',
            // i18n: 'draggableDynamicTable.actions.print',
            icon: 'icon-printer',
            iconPack: 'feather'
          },
          {
            id: 'downloadTable',
            // i18n: 'draggableDynamicTable.actions.download',
            icon: 'icon-download',
            iconPack: 'feather'
          },
          {
            id: {name: 'customers'},
            type: 'link',
            icon: 'icon-chevron-left',
            iconPack: 'feather'
          }
        ]
      },
      userDataForDelete: {}
    }
  },
  created () {
    if (!checkUserPermissions('user.forceDelete')) {
      for (let i = 0; i < this.columnsLabel.length; i++) {
        if (this.columnsLabel[i].field === 'deleteAction') {
          this.columnsLabel.splice(i, 1)
        }
      }
    }

    if (!checkUserPermissions('user.restore')) {
      for (let i = 0; i < this.columnsLabel.length; i++) {
        if (this.columnsLabel[i].field === 'storeAction') {
          this.columnsLabel.splice(i, 1)
        }
      }
    }

    setTimeout(() => {
      this.$store.dispatch('updateNavbarActions', this.actions)
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      this.$store.dispatch('updateContentNavbarClass', '')
    }, 50)

    this.getUsersTrash()
  },
  methods: {
    getUsersTrash () {
      if (!this.requestSent) {
        this.requestSent = true

        clearTimeout(this.loadingTimer)
        this.loadingTimer = setTimeout(() => {
          if (!this.endedList) {
            if (this.$refs.customersTrashList && this.data.length === 0) {
              this.$refs.customersTrashList.loadMoreStatus = 'FirstLoad'
            } else if (this.$refs.customersTrashList && this.data.length > 0) {
              this.$refs.customersTrashList.loadMoreStatus = 'Loading'
            }

            getUsersTrash(this.page, this.filters, this.sorts).then((res) => {
              this.endedList = res.data.data.length === 0
              res.data.data.forEach((user) => {
                const shops = []
                if (user.received && user.received.synced) {
                  shops.push(user.received.shop.name)
                }

                this.data.push({
                  row: this.data.length + 1,
                  id: user.id,
                  registryDate: user.created_at,
                  gender: user.gender === 1 ? this.$t('users.genderTypes.man') : this.$t('users.genderTypes.woman'),
                  phoneNumber: user.phone_number ? user.phone_number : '',
                  firstName: user.name || '',
                  lastName: user.family || '',
                  name: `${  user.name  } ${  user.family}`,
                  nationalCode: user.national_code ? user.national_code : '',
                })
              })

              this.page = res.data.pagination.current_page + 1
              if (res.data.pagination.current_page === 1) {
                const row_index = this.columnsLabel.map((e) => {
                  return e.field
                }).indexOf('row')
                this.columnsLabel[row_index].footer.value = res.data.pagination.total
              }

              if (this.$refs.customersTrashList) this.$refs.customersTrashList.loadMoreStatus = ''

              this.requestSent = false
            })
              .catch(() => {
                if (this.$refs.customersTrashList) this.$refs.customersTrashList.loadMoreStatus = 'Danger'

                this.requestSent = false
              })
          } else {

            this.requestSent = false
          }
        }, 400)
      }

    },
    setFilters (filters) {
      const filters_list = []
      Object.keys(filters).forEach((key) => {
        switch (key) {

          case 'gender':
            if (filters[key].search.value > 0) filters_list.push(`gender=${  filters[key].search.value }`)
            break

          case 'phoneNumber':
            if (filters[key].search !== '') filters_list.push(`phone_number=${  filters[key].search}`)
            break

          case 'name':
            if (filters[key].search !== '') filters_list.push(`full_name=${  filters[key].search}`)
            break

          case 'registryDate':
            if (filters[key].search.length > 0) filters_list.push(`created_at=${  filters[key].search.join('_')}`)
            break
        }
      })
      if (this.roleId > 0) {
        filters_list.push(`role=${this.roleId}`)
      }

      this.data = []
      this.page = 1
      this.endedList = false
      this.filters = filters_list
      this.getUsersTrash()
    },
    setSort (columns) {
      const sorts_list = []
      Object.keys(columns).forEach((key) => {
        switch (key) {

          case 'row':
            sorts_list.push(`order[0]=id,${  columns[key] ? 'desc' : 'asc'}`)
            break

          case 'phoneNumber':
            sorts_list.push(`order[0]=phone_number,${  columns[key] ? 'desc' : 'asc'}`)
            break

          case 'name':
            sorts_list.push(`order[0]=name,${  columns[key] ? 'desc' : 'asc'}`)
            break

          case 'registryDate':
            sorts_list.push(`order[0]=created_at,${  columns[key] ? 'desc' : 'asc'}`)
            break

          case 'gender':
            sorts_list.push(`order[0]=gender,${  columns[key] ? 'desc' : 'asc'}`)
            break
        }
      })

      if (!sorts_list.length) sorts_list.push('order[0]=created_at,desc')

      this.data = []
      this.page = 1
      this.endedList = false
      this.sorts = sorts_list
      this.getUsersTrash()
    },
    restoreUser (user) {
      restoreUser(user.id).then(res => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('users.user.notifications.restore.success'),
          color: 'success',
          time: 2400,
          icon: 'icon-check-circle',
          iconPack: 'feather'
        })

        this.data = []
        this.getUsersTrash()
      })
        .catch(error => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              title: this.$t('alert.error.title'),
              text: this.$t('users.user.notifications.restore.error'),
              color: 'danger',
              time: 2400,
              icon: 'icon-alert-circle',
              iconPack: 'feather'
            })
          }
        })
    },
    deleteUser (user) {
      deleteUser(user.id, true).then(res => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('users.user.notifications.delete.success'),
          color: 'success',
          time: 2400,
          icon: 'icon-check-circle',
          iconPack: 'feather'
        })

        this.data = []
        this.getUsersTrash()
      })
        .catch(error => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            switch (error.response.status) {
              case 409:
                this.$vs.notify({
                  title: this.$t('alert.error.title'),
                  text: this.$t('users.user.notifications.delete.isUsedError'),
                  color: 'danger',
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 5000
                })
                break

              default:
                this.$vs.notify({
                  title: this.$t('alert.error.title'),
                  text: this.$t('users.user.notifications.delete.error'),
                  color: 'danger',
                  time: 2400,
                  icon: 'icon-alert-circle',
                  iconPack: 'feather'
                })
                break
            }
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
